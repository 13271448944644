import * as React from 'react';
import { MoodboardService }from '../Services/MoodboardService';
import { withRouter } from './wrappers/withRouter';

class MoodboardEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: '',
            id: this.props.router.params.moodboardId,
            isLoadingData: true,
            moodboard: {}
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let moodboard = MoodboardService.findOneById(this.state.id);

        this.setState({
            moodboard: moodboard
        }, this.forceUpdate);
    }

    async handleSubmit(e) {
        e.preventDefault();
        let results = await MoodboardService.update({
            id: this.state.id,
            notes: this.state.notes
        });

        this.setState({
            isLoadingData: false
        });
    }


    render() {
        // @TODO IMPLEMENT
        return <div>Moodboard Edit Page</div>
    }
}

export default withRouter(MoodboardEdit);