import { rootUrl } from "../Utilities/Utils";
import BaseService from "./BaseService";

export class SongVersionService extends BaseService {

    static resourceURI = 'songVersion';
    constructor() {
        super();
    }

    /**
     * @override to use a different content type than application/json
     * 
     * @param {*} params - object structure
     *      {
                file,
                songTitlePretty
            }
     * @param {*} token
     * // headers: { 'Content-Type': 'multipart/form-data' }, 
     * @returns 
     */
    static async create(params, token) {
        let data = new FormData();
        data.append('file', params.file);
        data.append('fileName', params.file.name);
        data.append('createdTime', params.file.lastModified);

        /*
         the id field is song title pretty since it's both the thing 
         we're updating and the value we're using to search for the record
        */
        data.append('id', params.songTitlePretty); 


        console.log("SERVICE: create OVERRIDE", params);
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        };

        let resultsRaw = await fetch(`${rootUrl}/${this.resourceURI}`, requestOptions);

        return resultsRaw;
    }

    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }
}