import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0Options } from './Utilities/Utils';

const root = ReactDOM.createRoot(document.getElementById("root"));

const auth = process.env.NODE_ENV === 'production' ? Auth0Options.production : Auth0Options.development;

root.render(
    <Auth0Provider
        domain={auth.domain}
        clientId={auth.clientId}
        authorizationParams={auth.authorizationParams}
        useRefreshTokens={true}
        cacheLocation='localstorage'
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Auth0Provider>
);
