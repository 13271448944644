import React, {Component} from "react";
import TextField from "@mui/material/TextField";
import {withRouter} from "./wrappers/withRouter";
import {SongService} from "../Services/SongService";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import AuthService from "../Services/AuthService";
import { withAuth0 } from '@auth0/auth0-react';

/**
 * @TODO NICK
 * pass in the necessary input as props e.g. song name, etc. then this thing can query all on it's own
 */
class AutocompleteSongSearchAll extends Component {
    constructor(props) {
        super(props);

        this.state = {
            songList: [],
            isLoadingData: true
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let allSongs = await SongService.findAll(token);

        this.setState({
            songList: allSongs,
            isLoadingData: false
        });
    }

    showSongDetailPage (e, value) {
        this.props.router.navigate(`/app/songs/${value.songTitlePretty}/`);
    }

    render() {
        return(
            <Box>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={this.state.isLoadingData === false ? this.state.songList : []}
                    getOptionLabel={(song) => song.songTitlePretty}
                    sx={{
                        minWidth: 250,
                        maxWidth: 700,
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#eeeeee",
                            borderWidth: 3,
                            borderRadius: 2
                        }
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                    {...params}
                                    label="Search your Songs"
                                    color="green"
                            />
                        )
                    }}
                    // onInputChange={(e, value) => this.props.handleSearchBarUpdate(e, value)}
                    onChange={(e, value) => this.showSongDetailPage(e, value)}
                />
            </Box>
        )
    }
}

export default withAuth0(withRouter(AutocompleteSongSearchAll));