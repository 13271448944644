import {withRouter} from "../wrappers/withRouter";
import {FileDropzoneBase} from "../FileDropzoneBase";
import {withAuth0} from "@auth0/auth0-react";
import {UPLOAD_STATUS} from "../pages/SyncFiles";

class SongIdeaFirstSongFileDropZone extends FileDropzoneBase {
    constructor(props) {
        super(props);
    }

    /**
     * @override
     * only one file allowed for the first song upload
     * 
     * @returns {boolean}
     */
    isMultipleFileUploadAllowed() {
        return false;
    }

    getDropZoneDisplayText() {
        return 'upload first song file :)';
    }

    /**
     * callback for when the files have been dropped
     * @param acceptedFiles
     */
    async onDrop(acceptedFiles) {
        // set our internal state
        this.setState({
            files: acceptedFiles
        });

        console.log("we're about to navigate to the sync page with details", {
            files: acceptedFiles,
            uploadStatus: UPLOAD_STATUS.VERIFY_SONGS,
            songTargetId: this.props.songTargetId,
        });

        this.props.router.navigate(`/app/sync`, {
            replace: true,
            state: {
                files: acceptedFiles,
                uploadStatus: UPLOAD_STATUS.VERIFY_SONGS,
                songTargetId: this.props.songTargetId,
            }
        });

        // just upload the file and have it take in the right details
    }

    /**
     * using the current state of the dropzone, change the styling appropriately
     * @param props
     * @returns {string}
     */
    getDropZoneColor(props) {
        console.log("grabbing color! with props", props);
        if (props.isDragAccept) {
            return '#00e676';
        }
        if (props.isDragReject) {
            return '#ff1744';
        }
        if (props.isFocused) {
            return '#2196f3';
        }
        return '#294591';
    }
}

export default withAuth0(withRouter(SongIdeaFirstSongFileDropZone));