import React from "react";
import { Container, Box, Tab, Tabs, tabClasses } from "@mui/material";
import Grid from '@mui/system/Unstable_Grid';
import { withRouter } from '../wrappers/withRouter';
import { ThemeProvider } from '@mui/material/styles';
import { LandingPageHeader } from '../headers/LandingPageHeader';
import { lighttheme } from "../../Utilities/MUIThemes";
import Typography from "@mui/material/Typography";
import { EditNote, Lyrics, SavedSearch, QueueMusic, Backup, TipsAndUpdates, Layers } from '@mui/icons-material';
import { BaseFeatureHighlight } from "../feature-highlight-tabs/BaseFeatureHighlight";
import { AllFeaturesData } from "../feature-highlight-tabs/FeaturesData";

class LandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0
        }
    }

    componentDidMount() {}

    handleTabChange(event, newValue) {
        event.preventDefault();
        console.log("handleTabChange: Tab changed to: ", newValue);

        this.setState({
            tabValue: newValue
        });
    }

    getTabDetailView(tabValue) {
        console.log("getTabView: ", tabValue);
        let featureData = this.getFeatureData(tabValue)

        return (
            <Grid xs={12} textAlign="center" spacing={2}>
                <BaseFeatureHighlight featureData={featureData}/>
            </Grid>
        );
    }

    getTabView() {
        return (
            <Box display="flex" justifyContent="center" width="100%">
                <Tabs 
                    value={this.state.tabValue}
                    onChange={(e, newValue) => this.handleTabChange(e, newValue)}
                    centered
                    variant="scrollable"
                    scrollButtons={true}
                    allowScrollButtonsMobile // allows the buttons to show on mobile
                    // TabIndicatorProps={{ // this is how you can style the indicator underline
                    //     style: {
                    //       backgroundColor: "#00FF00",
                    //       color: "#00FF00"
                    //     }
                    // }}
                    >
                    <Tab 
                        value={0}
                        sx={{ textTransform: "none", marginRight: 2, fontFamily: `'Manrope', 'sans-serif'`, }} 
                        icon={<EditNote />}
                        label="Notes" />
                    <Tab 
                        value={1}
                        sx={{ textTransform: "none", marginRight: 2, fontFamily: `'Manrope', 'sans-serif'`, }} 
                        icon={<Lyrics/>}
                        label="Lyrics" />
                    <Tab 
                        value={2}
                        sx={{ textTransform: "none", marginRight: 2, fontFamily: `'Manrope', 'sans-serif'`, }} 
                        icon={<SavedSearch/>}
                        label="Search" />
                    <Tab 
                        value={3}
                        sx={{ textTransform: "none", marginRight: 2, fontFamily: `'Manrope', 'sans-serif'`, }} 
                        icon={<QueueMusic/>}
                        label="Playlists" />
                    <Tab 
                        value={4}
                        sx={{ textTransform: "none", marginRight: 2, fontFamily: `'Manrope', 'sans-serif'`, }} 
                        icon={<Backup/>}
                        label="Backups" />
                    <Tab 
                        value={5}
                        sx={{ textTransform: "none", marginRight: 2, fontFamily: `'Manrope', 'sans-serif'`, }}
                        icon={<TipsAndUpdates/>}
                        label="Drafts" />
                    <Tab 
                        value={6}
                        sx={{ textTransform: "none", marginRight: 2, fontFamily: `'Manrope', 'sans-serif'`, }}
                        icon={<Layers/>}
                        label="Versions" />
                </Tabs>
            </Box>
        )
    }

    getFeatureData(tabValue) {
        console.log("getFeatureData: ", tabValue, AllFeaturesData[tabValue]);
        return AllFeaturesData[tabValue];
    }

    render() {
        return (
            <ThemeProvider theme={lighttheme}>
                <Box backgroundColor={lighttheme.palette.grey1.main} height={"100vh"}>
                    <Container
                        maxWidth={false}
                        sx={{
                            backgroundColor: lighttheme.palette.grey1.main,
                        }}>
                        <LandingPageHeader />
                        <Grid container spacing={2} sx={{
                            [`@media only screen and (min-width: 300px)`]: {
                                marginTop: 2,
                            },
                            [`@media only screen and (min-width: 700px)`]: {
                                marginTop: 4,
                            },
                            [`@media only screen and (min-width: 1000px)`]: {
                                marginTop: 8,
                            },
                        }}>
                            <Grid xs={12} textAlign="center" spacing={2}
                            sx={{
                                marginTop: 0,
                                marginBottom: 0,
                                [`@media only screen and (min-width: 500px)`]: {
                                    marginBottom: 2
                                },
                                [`@media only screen and (min-width: 1000px)`]: {
                                    marginBottom: 0
                                }
                            }}>
                                <Typography variant={"h1"} sx={{
                                    lineHeight: '5rem'
                                }}>
                                    &nbsp;turn ideas ➡️&nbsp;songs.<span style={{
                                        color: "#03d903",
                                        // fontSize: '5rem',
                                        // fontWeight: 700,
                                        fontFamily: `'Manrope', 'sans-serif'`,
                                    }}><br/> effortlessly.&nbsp;</span>
                                </Typography>
                            </Grid>
                            <br />
                            <Grid xs={12}
                            textAlign="center"
                            spacing={2}
                            sx={{
                                marginTop: 2,
                                marginBottom: 3,
                                [`@media only screen and (min-width: 200px)`]: {
                                    marginTop: 0,
                                    marginBottom: 2,
                                },
                                    [`@media only screen and (min-width: 700px)`]: {
                                        marginTop: 0,
                                        marginBottom: 2,
                                    },
                                [`@media only screen and (min-width: 1000px)`]: {
                                    marginTop: 2,
                                    marginBottom: 2
                                },
                                [`@media only screen and (min-width: 1300px)`]: {
                                    marginTop: 2,
                                    marginBottom: 2
                                },
                                }}
                            >
                                <Typography variant={"h4"} color="grey4.main">
                                    the ultimate framework to start, manage, and finish your music.
                                </Typography>
                            </Grid>
                            
                            <Grid xs={12} textAlign="center" spacing={2}>
                                    {this.getTabView()}
                            </Grid>

                            {this.getTabDetailView(this.state.tabValue)}
                        </Grid>
                    </Container>
                </Box>
                
            </ThemeProvider>
        )
    }
}

export default withRouter(LandingPage);