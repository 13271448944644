import * as React from "react";
import ProjectCard from "./cards/ProjectCard";
import { Link } from "react-router-dom";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Grid from "@mui/material/Grid";
import { withRouter } from './wrappers/withRouter';
import {ProjectService} from "../Services/ProjectService";
import AuthService from "../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";
import {darktheme} from "../Utilities/MUIThemes";
import CssBaseline from "@mui/material/CssBaseline";
import AppHeader from "./headers/AppHeader";
import {ThemeProvider} from "@mui/material/styles";
import {Container} from "@mui/material";
import {DefaultLoadingScreen} from "./DefaultLoadingScreen";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const fabStyle = {
    position: 'absolute',
    bottom: 40,
    right: 40,
};

class ProjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            isLoadingData: true
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let results = await ProjectService.findAll(token);

        this.setState({
            projects: results,
            isLoadingData: false
        });
    }

    getProjectGridView() {
        if(this.state.projects.length > 0){
            return (
                <Grid container
                      spacing={4}
                      direction="row"
                      marginTop={8}
                      paddingLeft={4}
                      paddingRight={4}>
                    {this.state.projects.map((project) => {
                        return (
                            <Grid item xs={12} sm={6} md={4}>
                                <ProjectCard name={project.name}/>
                            </Grid>
                        )
                    })}
                </Grid>
            )
        } else {
            return (
                <Box sx={{
                    display: 'flex'
                }}>
                    <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 5}}>
                        <Box textAlign={"center"}>
                            <Typography variant="h3">
                                Your songs have a new home.
                            </Typography>
                            <br/>
                            <Typography variant="body1">
                                <Link onClick={(e) => this.handleNewProjectPageLinkClick(e)}>Create</Link> a new project.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )
        }
    }

    handleNewProjectPageLinkClick(e) {
        e.preventDefault();

        this.props.router.navigate(`/app/project/new`);
    }

    /*
        <Box textAlign={"center"}>
              <Typography variant="h3">
                Your journey starts here.
              </Typography>
              <br/>
              <Typography variant="body1">
                Hit the <Link onClick={(e) => this.handleUploadPageLinkClick(e)}>Upload Page</Link> to start uploading
                songs.
              </Typography>
     */

    render() {
        if(!this.state.isLoadingData) {
            return (
                <ThemeProvider theme={darktheme}>
                    <CssBaseline />
                    <Container
                        maxWidth={false}
                        sx={{
                            // backgroundColor: darktheme.palette.primary.main,
                            // height: "100vh"
                            paddingRight: 1,
                            paddingLeft: 1
                        }}
                    >
                        <AppHeader />

                        {this.getProjectGridView()}
                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={fabStyle}
                        onClick={() => this.props.router.navigate(`/app/project/new`)}>
                        <AddIcon />
                    </Fab>
                    </Container>
                </ThemeProvider>
            )
        } else {
            return <DefaultLoadingScreen />
        }
    }
}

export default withAuth0(withRouter(ProjectList));