import * as React from 'react';
import { withRouter } from "../wrappers/withRouter";
import LowPriorityIcon from '../../assets/icons/svg-dot-grey.svg';
import RegularPriorityIcon from '../../assets/icons/svg-dot-blue.svg';
import HighPriorityIcon from '../../assets/icons/svg-dot-yellow.svg';
import HighestPriorityIcon from '../../assets/icons/svg-dot-red.svg';
import DraftArtwork from '../../assets/card-images/default-add-song-grey.png';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from "@mui/material/CardMedia";
import { SongService } from "../../Services/SongService";
import {withAuth0} from "@auth0/auth0-react";
import AuthService from "../../Services/AuthService";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { DateUtilities } from '../../DateUtilities';
import { FileService } from "../../Services/FileService";
import {rootUrl} from "../../Utilities/Utils";

class SongDraftCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            song: this.props.song,
            isLoadingData: true
        }
    }

    priorityMap = {
        0: LowPriorityIcon,
        1: RegularPriorityIcon,
        2: HighPriorityIcon,
        3: HighestPriorityIcon
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData(){
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let song = await SongService.findOneById(this.state.song.songTitlePretty, token);

        let presignedUrlForAlbumArt;
    
        if(song.primaryArtwork) {
            presignedUrlForAlbumArt = await FileService.findOneById(this.state.song.primaryArtwork.fileName, token);
        }

        this.setState({
            song: song,
            isLoadingData: false,
            albumArtURL: presignedUrlForAlbumArt
        }, this.forceUpdate());
    }

    /**
     * navigate to the details page using the current song identifier as the URL key
     * @param latestVersionFileName
     */
    showSongDetailPage() {
        this.props.router.navigate(`/app/songs/${encodeURIComponent(this.state.song.songTitlePretty)}/`);
    }

    getImageForCard() {
        return this.state.song.primaryArtwork ? this.state.albumArtURL.signedURL : DraftArtwork;
    }


    render() {
        let songId = this.state.song.songTitlePretty;

        if(!this.state.isLoadingData){
            return (
                <Card 
                    sx={{ 
                        // minWidth: 325, 
                        // minHeight: 345, 
                        borderRadius: '10px',
                        minWidth: 325,
                        minHeight: 345, 
                        border: '2px solid',
                        borderColor: this.state.isAudioPlaying ? "#00FF00" : "#121212",
                        // boxShadow: '5px 7px 11px 1px #4b4b4b',
                        "&:hover": {
                            border: '2px solid',
                            borderColor: this.state.isAudioPlaying ? "#00FF00" : "#FFFFFF",
                            // boxShadow: '5px 7px 11px 1px #00FF00',
                        }
                    }}>
                    <CardContent>
                        <CardMedia
                            sx={{
                                [`@media only screen and (min-width: 100px)`]: {
                                    height: 50,
                                },
                                [`@media only screen and (min-width: 700px)`]: {
                                    height: 90,
                                },
                                cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                            image={this.getImageForCard()}
                            title="default song artwork"
                            onClick={() => this.showSongDetailPage()}
                        >
                            <ModeEditIcon color="primary" sx={{
                                    [`@media only screen and (min-width: 100px)`]: {
                                display: "none"
                            },
                                [`@media only screen and (min-width: 700px)`]: {
                                display: "inline-block",
                                margin: "10px",
                            }}}/>
                        </CardMedia>
                        <br />

                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            -
                        </Typography>
                        <Typography variant="h5" component="div">
                            <img className="song-priority-icon" src={this.priorityMap[this.state.song.priority]}></img> {songId}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                           {DateUtilities.getDateDiffInDays(new Date(this.state.song.createdDate), new Date())} Days Ago
                        </Typography>
                        <Typography variant="body2" sx={{
                            [`@media only screen and (min-width: 300px)`]: {
                                marginBottom: 0,
                            },
                            [`@media only screen and (min-width: 700px)`]: {
                                marginBottom: 6,
                            }
                        }}>
                            {this.state.song.lyrics.sentiment}
                        </Typography>
                    </CardContent>
                </Card>
            )
        } else {
            return (
                <div>Loading...</div>
            )
        }
    }
}

export default withAuth0(withRouter(SongDraftCard));

/*
    for later:

    ** ADDING CARD ACTIONS **
    e.g. favorite, edit, etc.

    <CardActions>
        <Typography>
            <Badge badgeContent={numNotes}>
                <EditNoteIcon
                    onClick={() => this.showSongDetailPage(latestVersionFilename)}
                    color="action"
                    sx={{cursor: 'pointer'}}
                />
            </Badge>
        </Typography>
        // <FavoriteBorderIcon
        // color="action"
        // sx={{cursor: 'pointer'}}
    </CardActions>
 */