import { Auth0Options } from "../Utilities/Utils";

/**
 * @TODO NICK
 * make this a component
 */
class AuthService {

    static token;

    constructor() {
        this.init();
    }

    async init() {}

    static getToken() {
        return localStorage.getItem("sounds-sights-auth-token");
    }

    static setToken(token) {
        localStorage.setItem("sounds-sights-auth-token", token);
    }

    static async getTokenSilently(auth0) {
        console.log("getTokenSilently called with authentication statuses", auth0.isAuthenticated, auth0.isLoading);
        let isProduction = process.env.NODE_ENV === 'production';

        let authAudience = isProduction ? Auth0Options.production.authorizationParams.audience : Auth0Options.development.authorizationParams.audience;

        try {
            let token = await auth0.getAccessTokenSilently({
                authorizationParams: {
                    audience: authAudience,
                    scope: 'openid profile email offline_access'
                }
            })

            return token;
        } catch(error) {
            console.log("ERROR:", error);
            if (error.error !== 'login_required') {
                throw error;
            }
        }
    }

    /**
     * returns if the user is currently authenticated
     * @TODO figure out how to really check to see if their token is valid as well
     * NOTE TO NICK: this is clearly implemented in auth0 params in withAuth0 props - isAuthenticated. use that instead
     * @returns {boolean}
     */
    static isAuthenticated() {
        return Boolean(localStorage.getItem("sounds-sights-auth-token"));
    }

    /**
     * @TODO impelement
     * figure out how to get the auth0 context related stuff into a pure class
     * / if that is even the right approach
     */
    static logout(auth0) {
        localStorage.removeItem("sounds-sights-auth-token");
        auth0.logout();
    }
}

export default AuthService;