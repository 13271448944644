import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from '@mui/material/Checkbox';
import {rootUrl} from "../Utilities/Utils";
import {SongVersionNoteService} from "../Services/SongVersionNoteService";
import AuthService from "../Services/AuthService";
import {withAuth0} from "@auth0/auth0-react";



class SongVersionNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            timestamp: this.props.timestamp,
            notes: this.props.notes,
            completed: this.props.completed
        }
    }

    async handleVersionNoteCompletedCheckbox(e) {
        // e.preventDefault();

        console.log("handleVersionNoteCompletedCheckbox", this.state.completed);

        let newCompletedState = !this.state.completed;

        let token = await AuthService.getTokenSilently(this.props.auth0);

        let versionNote = await SongVersionNoteService.update({
            id: this.state.id,
            notes: this.state.latestVersionNotes,
            timestamp: this.state.timestamp,
            completed: newCompletedState ? 1 : 0
        }, token);

        this.setState({
            completed: newCompletedState
        });
    }

    getStyle() {
        let style = this.state.completed ? "line-through" : "none";
        return {
            textDecoration: style
        }
    }

    render() {
        let label = `${this.state.timestamp}: ${this.state.notes}`;
        return (
            <FormControl>
                 <FormControlLabel
                     label={label}
                     control={<Checkbox />}
                     checked={this.state.completed}
                     onChange={(e) => {this.handleVersionNoteCompletedCheckbox(e)}}>
                 </FormControlLabel>
            </FormControl>
        )
    }
}

export default withAuth0(SongVersionNote);