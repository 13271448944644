import { Component } from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import * as React from "react";
import newSongBackground from '../../assets/card-images/6.png'
import newSongIcon from '../../assets/icons/ss-new-song-icon.png'
import Box from "@mui/material/Box";
import {SongService} from "../../Services/SongService";
import { withAuth0 } from "@auth0/auth0-react";
import AuthService from "../../Services/AuthService";
import {withRouter} from "../wrappers/withRouter";

class StartNewSongCard extends Component {
    constructor(props) {
        super(props)
    }

    fetchData() {}

    /**
     * create a new song object on the back end and then navigate to the new song's front end experience
     * @returns {Promise<void>}
     */
    async createNewSong() {
        let token = await AuthService.getTokenSilently(this.props.auth0);
        let song = await SongService.create({}, token);

        console.log("NEW SONG CREATED", song);

        this.props.router.navigate(`/app/songs/${encodeURIComponent(song.songTitlePretty)}`);
    }

    getImageForCard() {}

    render() {
        return (
            <Card sx={{
                minWidth: 325,
                minHeight: 140,
                borderRadius: '10px'
            }}>
                <CardContent>
                    <CardMedia
                        sx={{
                            height: 125,
                            '&:hover': {
                                backgroundImage: `url(${newSongBackground})`
                            },
                            cursor: 'pointer',
                            borderRadius: '5px'
                        }}
                        // image={this.getImageForCard()}
                        title="default song artwork"
                        onClick={() => this.createNewSong()}
                        >
                        <Box
                            textAlign={"center"}
                        >
                            <Box paddingTop={3}>
                                <img
                                    src={newSongIcon}
                                    style={{
                                        width: 50,
                                        height: 50,
                                    }}
                                ></img>
                            </Box>
                        </Box>
                        <Typography variant={"body1"} color={"secondary"} textAlign={"center"}>
                            start a new song
                        </Typography>
                    </CardMedia>
                </CardContent>
            </Card>
        )
    }
}

export default withRouter(withAuth0(StartNewSongCard));