import BaseService from "./BaseService";

export class UserService extends BaseService {

    static resourceURI = 'users';
    constructor() {
        super();
    }

    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }
}