import * as React from 'react';
import { withRouter } from '../wrappers/withRouter';
import SongDetail from '../SongDetail';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { rootUrl } from '../../Utilities/Utils';
import CardMedia from "@mui/material/CardMedia";
import SongLyricsCard from '../cards/SongLyricsCard'
import SongEdit from '../SongEdit';
import AuthService from "../../Services/AuthService";
import { SongService } from "../../Services/SongService";
import { ProjectService } from "../../Services/ProjectService";
import { withAuth0 } from "@auth0/auth0-react";
import AppHeader from "../headers/AppHeader";
import { ThemeProvider } from "@mui/material/styles";
import { darktheme } from "../../Utilities/MUIThemes";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { DefaultLoadingScreen } from "../DefaultLoadingScreen";
import { SongPageFileDropzoneCard } from "../cards/SongPageFileDropzoneCard";
import { SongIdeaFileDropzoneCard } from "../cards/SongIdeaFileDropzoneCard";
import { FileService } from "../../Services/FileService";

const fabStyle = {
    position: "fixed",
    bottom: 40,
    right: 40,
};

class SongDetailsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            songTitlePretty: this.props.router.params.songTitlePretty,
            isLoadingData: true,
            songDetails: {},
            latestVersionNotes: '',
            projects: [],
            albumArtworkForUpload: {}
        }
    }

    componentDidMount() {
        console.log("AUTH", this.props.auth0.isAuthenticated, this.props.auth0.isLoading);
        this.fetchData();
    }

    async fetchData() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        let songData = await SongService.findOneById(this.state.songTitlePretty, token);
        let projectList = await ProjectService.findAll(token);

        let presignedUrlForAlbumArt;

        if(songData.primaryArtwork) {
            presignedUrlForAlbumArt = await FileService.findOneById(songData.primaryArtwork.fileName, token);
        }

        console.log("presignedUrlForAlbumArt", presignedUrlForAlbumArt);

        this.setState({
            projects: projectList,
            songDetails: songData,
            isLoadingData: false,
            albumArtURL: presignedUrlForAlbumArt
        });
    }

    handleMoodboardCreate() {
        this.fetchData();
    }

    /**
     * update function to pass down to children so that if they make updates, we can refresh our stuff.
     */
    async onUpdateSongTitlePretty(e, newSongTitlePretty) {
        e.preventDefault();
        console.log("Song Details list: onUpdateSongTitlePretty", newSongTitlePretty);
        this.setState({
            songTitlePretty: newSongTitlePretty
        }, async () => {
            console.log(this.state);
            await this.fetchData();
            this.forceUpdate();
        })
    }

    getAlbumArtHeaderImage() {
        return this.state.songDetails.primaryArtwork ? this.state.albumArtURL.signedURL : '';
    }

    getSongDetailsCoverView() {
        if(this.props.auth0.isAuthenticated) {
            if(this.state.songDetails.primaryArtwork) {
                return (
                    <div>
                        <Grid item xs={12} sm={12}>
                            <Card sx={{ minWidth: 325, minHeight: 350 }}>
                                <CardContent>
                                    <CardMedia
                                        image={this.getAlbumArtHeaderImage()}
                                        title="song artwork"
                                        sx={{
                                            height: 150
                                        }}>
                                    </CardMedia>
                                    <Typography variant={"h1"} align={"center"}>
                                        {this.state.songTitlePretty}
                                    </Typography>
                                    {this.getMoodBoardView()}
                                    <Typography variant={"h5"} align={"center"}><Link to="/app">Home</Link></Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </div>
                )
            } else {
                return (
                    <Box
                        marginBottom={2}
                        marginTop={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant={"h1"} align={"center"}>
                                {this.state.songTitlePretty}
                            </Typography>
                            {this.getMoodBoardView()}
                        </Grid>
                    </Box>
                )
            }
        } else if (!this.props.auth0.isAuthenticated && this.props.auth0.isLoading) {
            return (
                <DefaultLoadingScreen />
            )
        }
    }

    /**
     * @TODO NICK - add to the dropzone functionality so that we can keep this here
     * and it can add new song files to the current song directly
     * 
     * only show the dropzone if we have version history
     * @returns {JSX.Element}
     */
    getDropzoneView() {
        if(this.state.songDetails.versionHistory.length == 0) {
            return (
                <Grid item xs={12} md={6}>
                    <SongIdeaFileDropzoneCard songTargetId={this.state.songDetails.songTitlePretty} />
                </Grid>
            )
        }
    }

    getMoodBoardView() {
        // if(this.state.songDetails.project && this.state.songDetails.moodboard) {
        //     return (
        //         <div>
        //             <Typography variant={"h6"} align={'center'}>Project Mood: {this.state.songDetails.project.moodboard.notes}</Typography>
        //             <Typography variant={"body1"} align={'center'}>Song Mood: {this.state.songDetails.moodboard.notes}</Typography>
        //         </div>
        //     )
        // }
        // // if we don't have a project moodboard, but we do have a song moodboard, display that
        // else if(this.state.songDetails.moodboard) {
        //     // @TODO create a moodboard view component - also this is kind of specific so let's just make it look nice for now
        //     return (
        //         <div>
        //             <Typography variant={"h6"} align={'center'}>Song Mood: {this.state.songDetails.moodboard.notes}</Typography>
        //         </div>
        //     )
        // }
        // else if(this.state.songDetails.project && this.state.songDetails.project.moodboard) {
        //     // @TODO create a moodboard view component - also this is kind of specific so let's just make it look nice for now
        //     return (
        //         <div>
        //             <Typography variant={"h6"} align={'center'}>Project Mood: {this.state.songDetails.project.moodboard.notes}</Typography>
        //             <Typography>Create New Moodboard</Typography>
        //             <MoodboardCreate onSubmit={(e) => {
        //                 this.handleMoodboardCreate(e)
        //             }} songId={this.state.songDetails.songIdentifier}/>
        //         </div>
        //     )
        // } else {
        //     return (
        //         <div>
        //             <Typography>Create New Moodboard</Typography>
        //             <MoodboardCreate onSubmit={(e) => {
        //                 this.handleMoodboardCreate(e)
        //             }} songId={this.state.songDetails.songIdentifier}/>
        //         </div>
        //     )
        // }
    }

    render() {
        if(!this.state.isLoadingData) {
            return (
                <ThemeProvider theme={darktheme}>
                    <CssBaseline />
                    <Container
                        maxWidth={false}
                        sx={{
                            // backgroundColor: darktheme.palette.primary.main,
                            // height: "100vh"
                            paddingRight: 1,
                            paddingLeft: 1
                        }}
                    >
                        <AppHeader />
                        <Grid
                              spacing={4}
                              direction="row"
                              sx={{
                                  display: 'grid',
                                  marginTop: 0,
                                  marginLeft: 1,
                                  marginRight: 1
                                  // width: '100%'
                              }}
                        >
                            {this.getSongDetailsCoverView()}
                        </Grid>
                        <Box sx={{
                            display: 'flex',
                            padding: 2
                        }}>
                    <Grid container
                          spacing={4}
                          alignContent={"center"}
                          direction="row"
                        >
                    {this.state.songDetails.versionHistory
                        .slice(0, 2) // assuming the version history is sorted by version, just grab the first two
                        .map((version, index) => {
                            return (
                                <Grid item xs={12} md={6}>
                                    <SongDetail key={version.id} version={version} />
                                </Grid>
                            );
                    })}
                            <Grid item xs={12} md={this.state.songDetails.versionHistory.length > 0 ? 6 : 12}> 
                                {/* if we have version history, make this half screen with 
                            other stuff, otherwise make it full screen in draft mode */}
                                <SongLyricsCard song={this.state.songDetails}/>
                            </Grid>
                            {this.getDropzoneView()}
                            <Grid item xs={12} md={6}>
                                <SongEdit
                                    songTitlePretty={this.state.songDetails.songTitlePretty}
                                    // if we're editing song fields that matter to us here, use this callback to let us know
                                    onUpdateSongTitlePretty={(e, newSongTitlePretty) => this.onUpdateSongTitlePretty(e, newSongTitlePretty)}
                                />
                            </Grid>
                        </Grid>
                        </Box>
                    </Container>
                </ThemeProvider>
            )
        } else {
            return (
                <DefaultLoadingScreen />
            )
        }

    }
}

export default withAuth0(withRouter(SongDetailsList));