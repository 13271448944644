/*
    This file contains the data for the Features component. Each object in the array represents a feature tab.
*/

// screenshot imports
import NotesScreenshot from '../../assets/screenshots/ss-screenshot-feature-notes.png';
import LyricsScreenshot from '../../assets/screenshots/ss-screenshot-feature-lyrics.png';
import SearchScreenshot from '../../assets/screenshots/ss-screenshot-feature-search.png';
import ProjectsScreenshot from '../../assets/screenshots/ss-screenshot-feature-projects.png';
import BackupsScreenshot from '../../assets/screenshots/ss-screenshot-feature-backups.png';
import DraftsScreenshot from '../../assets/screenshots/ss-screenshot-feature-drafts2.png';
import VersionsScreenshot from '../../assets/screenshots/ss-screenshot-feature-versions.png';


// icon imports
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import SaveIcon from '@mui/icons-material/Save';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PsychologyIcon from '@mui/icons-material/Psychology';
import MapIcon from '@mui/icons-material/Map';
import AbcIcon from '@mui/icons-material/Abc';
import AlbumIcon from '@mui/icons-material/Album';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import DownloadIcon from '@mui/icons-material/Download';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RestoreIcon from '@mui/icons-material/Restore';
import SortIcon from '@mui/icons-material/Sort';
import CompareIcon from '@mui/icons-material/Compare';

export const Notes = {
    header: 'Notes',
    subHeader: 'Easily keep track of your tracks.',
    featureList: [
        {
            description: 'Timestamped',
            icon: <AccessTimeIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'Mark as complete',
            icon: <CheckBoxIcon/>,
            currentlyAvailable: true
        }
    ],
    screenshot: NotesScreenshot
}

export const Lyrics = {
    header: 'Lyrics',
    subHeader: 'Refine your lyrics. Tell your story.',
    featureList: [
        {
            description: 'Freeform scratchpad',
            icon: <MenuBookIcon/>, 
            currentlyAvailable: true
        },
        {
            description: 'Styled fonts',
            icon: <FormatColorTextIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'Auto-save (coming soon)',
            icon: <SaveIcon/>,
            currentlyAvailable: false
        },
        {
            description: 'S&S lyric expander (coming soon)',
            icon: <AutoFixHighIcon/>,
            currentlyAvailable: false
        },
    ],
    screenshot: LyricsScreenshot
}

export const Search = {
    header: 'Search',
    subHeader: 'Find any song. In a snap.',
    featureList: [
        {
            description: 'Smart token matching',
            icon: <PsychologyIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'Easy navigation',
            icon: <MapIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'S&S Smart Search (coming soon)',
            icon: <AutoFixHighIcon/>,
            currentlyAvailable: false
        }
    ],
    screenshot: SearchScreenshot
}

export const Playlists = {
    header: 'Playlists',
    subHeader: 'Organize your music. Your way.',
    featureList: [
        {
            description: 'Named Playlists',
            icon: <AbcIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'Playlist Types',
            icon: <AlbumIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'S&S Smart Playlists (coming soon)',
            icon: <AutoFixHighIcon/>,
            currentlyAvailable: false
        }
    ],
    screenshot: ProjectsScreenshot
}

export const Backups = {
    header: 'Backups',
    subHeader: 'Rest assured. Your sounds are safe.',
    featureList: [
        {
            description: '99.999999999% durability with S3',
            icon: <CloudDoneIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'Self-serve downloads (coming soon)',
            icon: <DownloadIcon/>,
            currentlyAvailable: false
        }
    ],
    screenshot: BackupsScreenshot
}

export const Drafts = {
    header: 'Drafts',
    subHeader: 'Leave no idea behind.',
    featureList: [
        {
            description: 'Save drafts',
            icon: <ModeEditIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'Draft history (coming soon)',
            icon: <RestoreIcon/>,
            currentlyAvailable: false
        }
    ],
    screenshot: DraftsScreenshot
}

export const Versions = {
    header: 'Versions',
    subHeader: 'Make a change. Or fifty.',
    featureList: [
        {
            description: 'Automatic version sorting',
            icon: <SortIcon/>,
            currentlyAvailable: true
        },
        {
            description: 'Version comparison',
            icon: <CompareIcon/>,
            currentlyAvailable: true
        }
    ],
    screenshot: VersionsScreenshot
}

export const AllFeaturesData = [Notes, Lyrics, Search, Playlists, Backups, Drafts, Versions];