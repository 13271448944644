import { Grid, Typography, Box, textFieldClasses, Stack } from '@mui/material';
import React from 'react';

export class BaseFeatureHighlight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Box 
                paddingTop={2}
                paddingRight={1}
                paddingLeft={1}
                display="flex" 
                justifyContent="center" 
                width="100%">
                <Grid
                    container
                    direction="row"
                    sx={{
                        // border: 4,
                        borderRadius: 4,
                        borderColor: "#542cb8",
                        minHeight: "375px",
                        // maxHeight: "500px",
                        // backgroundColor: "#542cb8",
                        backgroundColor: "#252525",
                        maxWidth: "1200px"
                    }}>
                        <Grid item xs={12} md={8} paddingTop={8}>
                            <Box
                                component="img"
                                sx={{
                                    // height: 600,
                                    // width: 1070,
                                    maxWidth: '80%',
                                    boxShadow: "4px 5px 10px #209420",
                                    borderRadius: 4,
                                    // madHeight: "70%",
                                    // [`@media only screen and (min-width: 500px)`]: {
                                    //     width: '90%'
                                    // },
                                    // [`@media only screen and (min-width: 1000px)`]: {
                                    //     width: '80%'
                                    // }
                                }}
                                alt="Sounds and Sights Feature Screenshot"
                                src={this.props.featureData.screenshot}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} textAlign={"left"}>
                            <Box paddingTop={6}  paddingLeft={4} paddingRight={4} paddingBottom={4}>
                                <Box marginBottom={1}>
                                    <Typography 
                                        variant={"h3"} 
                                        color="grey2.main">
                                            {this.props.featureData.header}
                                    </Typography>
                                </Box>
                                <Box marginBottom={4}>
                                    <Typography 
                                        variant={"p"} 
                                        color="grey1.main">
                                            {this.props.featureData.subHeader}
                                    </Typography>
                                </Box>
                                {this.props.featureData.featureList.map((feature) => {
                                    return (
                                        <Stack alignItems="center" direction="row" gap={1} marginTop={1}>
                                            <span style={{
                                                color: "#03d903",
                                            }}>{feature.icon}</span>
                                            <Typography variant="body1">{feature.description}</Typography>
                                        </Stack>
                                        )
                                    })
                                }
                            </Box>
                        </Grid>
                </Grid>
            </Box>
        )
    }
}