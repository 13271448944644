import BaseService from "./BaseService";

/**
 * @TODO convert this to the base service
 */
export class MoodboardService extends BaseService {

    static resourceURI = 'moodboard';
    constructor() {
        super();
    }
    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }
}