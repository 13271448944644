import BaseService from "./BaseService";

export class SongService extends BaseService {

    static resourceURI = 'songs';
    constructor() {
        super();
    }

    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }
}