import { Component } from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import cardBackground from '../../assets/card-images/default-add-song-grey.png'
import SongPageFileDropZone from "../file-dropzones/SongPageFileDropZone";


/**
 * Represents a card component for the Song Page File Dropzone.
 * 
 * @class SongPageFileDropzoneCard
 * @extends {Component}
 * 
 * @param {Object} props - The properties passed to the component.
 * 
 * @method fetchData
 * Fetches data required for the component.
 * 
 * @method getImageForCard
 * Retrieves the background image for the card.
 * @returns {string} The background image for the card.
 * 
 * @method render
 * Renders the component.
 * @returns {JSX.Element} The rendered component.
 */
export class SongPageFileDropzoneCard extends Component {
    constructor(props) {
        super(props);
    }

    fetchData() {}

    getImageForCard() {
        return cardBackground;
    }

    render() {
        return (
            <Card 
                sx={{ 
                    minWidth: 325, 
                    minHeight: 150, 
                    borderRadius: '10px',
                    [`@media only screen and (max-width: 700px)`]: {
                        display: 'none',
                    },
                }}>
                <CardContent>
                    <SongPageFileDropZone />
                </CardContent>
            </Card>
        )
    }
}