import BaseService from "./BaseService";
import { rootUrl } from "../Utilities/Utils";
import AuthService from "./AuthService";

export class FileService extends BaseService {

    static resourceURI = 'media';
    constructor() {
        super();
    }
    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }

    // headers: { 'Content-Type': 'multipart/form-data' },
    static async create(params, token) {
        console.log("SERVICE: create OVERRIDE", params);
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: params
        };

        let resultsRaw = await fetch(`${rootUrl}/${this.resourceURI}`, requestOptions);

        return resultsRaw;
    }

    /**
     * @TODO NICK 06-22-2024 this is currently serially going through and saving - could be done in parallel.
     * @param fileList
     * @returns {Promise<void>}
     */
    static async createBulk(fileList, token) {
        let resultsAccumulator = [];

        for (var i = 0; i < fileList.length; i++) {
            let data = new FormData();
            data.append('file', fileList[i]);
            data.append('fileName', fileList[i].name);
            data.append('createdTime', fileList[i].lastModified);

            let fileCreated = await this.create(data, token);
            resultsAccumulator.push(fileCreated);
        }

        return resultsAccumulator;
    }

    /**
     * @OVERRIDE
     * specifically overriding the way that we process the data afterwards
     * e.g. in the other requests, the data is JSON and in this case, it's a BLOB / STREAM so we handle it differently.
     * @param id
     * @returns {Promise<Response<any, Record<string, any>, number>>}
     */
    static async findOneById(id, token) {
        console.log("SERVICE: findOneById", id);

        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            },
        }

        let results = await fetch(`${rootUrl}/${this.resourceURI}/${encodeURIComponent(id)}`, requestOptions);

        return results.json();
    }
}