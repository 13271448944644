import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FileService } from "../../Services/FileService";
import AuthService from "../../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";
import { withRouter } from "../wrappers/withRouter";
import { getFileExtension} from "../../Utilities/Utils";
import Box from "@mui/material/Box";
import { darktheme } from "../../Utilities/MUIThemes";
import CssBaseline from "@mui/material/CssBaseline";
import AppHeader from "../headers/AppHeader";
import { ThemeProvider } from "@mui/material/styles";
import { Container, Divider, LinearProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SongService } from "../../Services/SongService";
import { SongVersionService } from '../../Services/SongVersionService';

export const UPLOAD_STATUS = {
    "CHOOSE_SONGS": "Choose your Songs",
    "VERIFY_SONGS": "Doublecheck your Songs",
    "UPLOAD_STARTED": "Uploading your Songs",
    "UPLOAD_FINISHED_WITH_ERROR": "Something went wrong. Try Again!",
    "UPLOAD_FINISHED_WITH_SUCCESS": "Upload Successful!"
}

export const ERROR_CODE_TO_PRETTIFIED_MESSAGE = {
    400: "Error - Bad Request. Probably due to an invalid file type.",
    401: "Error - Unauthorized. Try logging back in again.",
    500: "Error - Something went wrong. Try again."
}

class SyncFiles extends React.Component {

    constructor(props) {
        super(props);

        // check to see if we have specific state from our routing, else use defaults
        // @TODO NICK if we start to pass state from other routes, are we going to accidentally consume it?
        this.state = this.props.router.location.state || {
            files: [],
            uploadStatus: UPLOAD_STATUS.CHOOSE_SONGS,
            uploadErrorMessage: ''
        }
    }

    handleFileSelected(e) {
        e.preventDefault();

        console.log(e.target.files);

        this.setState({
            files: e.target.files,
            uploadStatus: UPLOAD_STATUS.VERIFY_SONGS
        });
    }

    async handleFileSubmit(e) {
        e.preventDefault();

        let data = new FormData();

        data.append('files', this.state.files);

        this.setState({
            uploadStatus: UPLOAD_STATUS.UPLOAD_STARTED
        }, async () => {
            try {
                let token = await AuthService.getTokenSilently(this.props.auth0);


                /*
                    
                    CASE 1:
                    we want to specifically upload a file to a specific SONG, one that doesn't have any
                    current song versions. This is the first song version upload for a song.

                    OR

                    CASE 2:
                    if we are uploading a song where if there is no matching song identifier, it will
                    create a brand new song. and if there is a match already, then we will add this song version
                    to the matching song.
                 */
                let result;
                let isError = false;
                let firstOccuringErrorMessage = '';

                if(this.state.songTargetId) {
                    let songIdentifier = this.state.files[0].name.split('-')[0];

            

                    try {
                        // update the song with the new song identifier
                        let songUpdateResult = await SongService.update({
                            id: this.state.songTargetId,
                            songIdentifier: songIdentifier
                        }, token);
    
                        // create a new song version service
                        let songVersionCreateResult = await SongVersionService.create({
                            file: this.state.files[0],
                            songIdentifier: this.state.songTargetId
                        }, token);

                        // set the state to the success state
                        this.setState({
                            uploadStatus: UPLOAD_STATUS.UPLOAD_FINISHED_WITH_SUCCESS,
                            uploadErrorMessage: '',
                            files: []
                        });

                        // navigate to the app page
                        this.props.router.navigate(`/app`);

                    } catch (error) {
                        this.setState({
                            uploadStatus: UPLOAD_STATUS.UPLOAD_FINISHED_WITH_ERROR,
                            uploadErrorMessage: 'something went wrong during upload',
                            files: []
                        });
                    }
                } else {
                    result = await FileService.createBulk(this.state.files, token);

                    // there could have been non-success codes e.g. 400 bad request that we want to still count as error conditions
                    result.forEach((fileUploadResponse) => {
                        if(fileUploadResponse.status !== 200) {
                            isError = true;
                            firstOccuringErrorMessage = ERROR_CODE_TO_PRETTIFIED_MESSAGE[fileUploadResponse.status];
                        }
                    });

                    this.setState({
                        uploadStatus: isError ? UPLOAD_STATUS.UPLOAD_FINISHED_WITH_ERROR : UPLOAD_STATUS.UPLOAD_FINISHED_WITH_SUCCESS,
                        uploadErrorMessage: isError ? firstOccuringErrorMessage : '',
                        files: []
                    });

                    /*
                        @TODO NICK decide if this is the best user experience.
                        e.g. stay on page and see the success messaging and then navigate over vs. automatically just going
                    */
                    if(!isError) {
                        this.props.router.navigate(`/app`);
                    }
                }

            } catch(e) {
                console.log("File Submit finished - ERROR.", e);

                this.setState({
                    uploadStatus: UPLOAD_STATUS.UPLOAD_FINISHED_WITH_ERROR,
                    uploadErrorMessage: ERROR_CODE_TO_PRETTIFIED_MESSAGE["500"],
                    files: []
                });
            }
        })
    }

    getFileListForDisplay() {
        const allFiles = {
            validFiles: [],
            invalidFiles: []
        }

        if(this.state.files) {
            for (var i = 0; i < this.state.files.length; i++) {
                let fileExtension = getFileExtension(this.state.files[i].name);
                if(fileExtension !== 'mp3' && fileExtension !== 'wav' && fileExtension !== 'aif'){
                    allFiles.invalidFiles.push(this.state.files[i].name);
                } else {
                    allFiles.validFiles.push(this.state.files[i].name);
                }
            }

            return allFiles;
        } else {
            return [];
        }
    }

    /**
     * @TODO return a colorized version of the filename with the invalid filetype highlighted
     * @param fileName
     */
    // getColoredFileExtension(fileName) {
    //     let fileExtension = this.state.files[i].name.split('.')[this.state.files[i].name.split('.').length - 1];
    // }

    getHeaderByCurrentState() {
        if(this.state.uploadStatus === UPLOAD_STATUS.CHOOSE_SONGS) {
            return (
                <Box>
                    <Typography textAlign={"center"} variant="h3">
                        Upload away.
                    </Typography>
                    <br/>

                    <Typography textAlign={"center"} variant="body1">
                        upload your song bounces to start your <br/>
                        brand new life as a creator.
                    </Typography>

                    <br/>
                    <Divider></Divider>
                    <br/>
                    <Typography textAlign={"center"} variant="h5">
                        IMPORTANT NOTE:
                    </Typography>
                    <Typography textAlign={"center"} variant="h6">
                        while we are in beta, projects must have a dash (-) between <br/>
                        the project name and other info to be grouped together correctly.
                    </Typography>
                    <br/>
                    <Typography textAlign={"center"} variant="h6">
                        Examples:
                    </Typography>
                    <Typography textAlign={"center"}>
                        <Typography color="textSecondary" variant="h6">
                            GOOD: mysongname-5.mp3, mysongname-110-aminor.wav, songtitlehere-biggerlouder.aif
                        </Typography>
                        <Typography color="textSecondary" variant="h6">
                            BAD: mysongname.mp3, mysongnamebetter.wav, songtitleherebiggerlouder.aif
                        </Typography>
                    </Typography>
                    <br/>
                    <Typography textAlign={"center"}>
                        This will be updated soon with smart grouping. But for now, different versions <br/>
                        of the same song will not be auto-detected without dashes. Happy uploading!
                    </Typography>
                </Box>
            )
        }

        if(this.state.uploadStatus === UPLOAD_STATUS.UPLOAD_STARTED) {
            return (
                <Box>
                    <Typography textAlign={"center"} variant="h3">
                        Now we wait.
                    </Typography>
                    <br/>
                    <Typography textAlign={"center"} variant="body1">
                        ...
                    </Typography>
                    <br/>
                    <Box maxWidth={"500px"} margin={"auto"}>
                        <LinearProgress color="secondary"/>
                    </Box>
                </Box>
            );
        }

        if(this.state.uploadStatus === UPLOAD_STATUS.VERIFY_SONGS) {
            return (
                <Box>
                    <Typography textAlign={"center"} variant="h3">
                        Double check 👀
                    </Typography>
                    <Typography textAlign={"center"} variant="body2">
                        .mp3, .wav, & .aif music filetypes are currently supported
                    </Typography>
                    <Box textAlign={"center"} marginTop={4}>
                        <Box>
                            Valid Files ✅
                            {this.getFileListForDisplay().validFiles.map((filename) => {
                                return (
                                    <Typography variant="body1">
                                        {filename}
                                    </Typography>
                                )
                            })}
                        </Box>
                        <br />

                        <Box>
                            {this.getFileListForDisplay().invalidFiles.length > 0 ? <div>Invalid Files ❌ (upload will be skipped)</div> : ''}
                            {this.getFileListForDisplay().invalidFiles.map((filename) => {
                                return (
                                    <Box>
                                        <Typography variant="body1">
                                            {filename}
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            )
        }

        if(this.state.uploadStatus === UPLOAD_STATUS.UPLOAD_FINISHED_WITH_ERROR) {
            return (
                <Box>
                    <Typography textAlign={"center"} variant="h3">
                        Uh oh!
                    </Typography>
                    <br />

                    <Typography textAlign={"center"} variant="body1">
                        {this.state.uploadErrorMessage}
                    </Typography>
                </Box>
            )
        }

        if(this.state.uploadStatus === UPLOAD_STATUS.UPLOAD_FINISHED_WITH_SUCCESS) {
            return (
                <Box>
                    <Typography textAlign={"center"} variant="h3">
                        Success!
                    </Typography>
                    <br />

                    <Typography textAlign={"center"} variant="body1">
                        Tap the big Sounds and Sights logo in the upper left <br/>
                        or tap "home" in the menu to see your newly organized library!
                    </Typography>
                </Box>
            )
        }
    }

    /**
     * only show the submit button if we're in a place that we can submit
     * @returns {Element}
     */
    getCTAButton() {
        if(this.state.uploadStatus === UPLOAD_STATUS.VERIFY_SONGS) {
            return (
                <Button
                    id="uploadfiles-button"
                    sx={{
                        marginRight: "20px",
                        backgroundColor: "#00FF00",
                        textTransform: "lowercase",
                    }}
                    variant="contained"
                    type={"submit"}>
                    Submit
                </Button>
            )
        }

        // if(this.state.uploadStatus === UPLOAD_STATUS.UPLOAD_FINISHED_WITH_SUCCESS) {
        //     return (
        //         <Button
        //             id="uploadfiles-button"
        //             sx={{
        //                 marginRight: "20px",
        //                 backgroundColor: "#00FF00",
        //                 textTransform: "lowercase",
        //             }}
        //             variant="contained"
        //             onClick={() => this.props.router.navigate(`/app`)}
        //         >
        //             See your Songs
        //         </Button>
        //     )
        // }
    }

    render() {
        return (
            <ThemeProvider theme={darktheme}>
                <CssBaseline />
                <Container
                    maxWidth={false}
                    sx={{
                        // backgroundColor: darktheme.palette.primary.main,
                        // height: "100vh"
                        paddingRight: 1,
                        paddingLeft: 1
                    }}
                >
                    <AppHeader />
                        <Box marginTop={6}>
                            <Grid item xs={12}>
                                <Box>
                                    {this.getHeaderByCurrentState()}
                                </Box>

                                <Box textAlign={"center"} marginTop={4}>
                                    <Box marginTop={10}>
                                        <form onSubmit={(e) => this.handleFileSubmit(e)}>
                                            <input
                                                id="icon-button-photo"
                                                onChange={(e) => this.handleFileSelected(e)}
                                                type="file"
                                                accept=".mp3, .wav, .aif"
                                                multiple
                                            />
                                            {this.getCTAButton()}
                                        </form>
                                    </Box>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Box>
                            </Grid>
                        </Box>
                </Container>
            </ThemeProvider>
        )
    }
}

export default withAuth0(withRouter(SyncFiles));