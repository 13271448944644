import {Component} from "react";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from "@mui/material/Typography";
import * as React from "react";

export class FileDropzoneBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: []
        }
    }

    /**
     * to be implemented
     * when the files are created
     */
    onDrop() {}

    getDropZoneColor() {}

    isMultipleFileUploadAllowed() {}

    getDropZoneDisplayText() {}

    render() {
        return (
            <Dropzone 
                onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)} 
                accept={{
                    'audio/mpeg': ['.mp3'],
                    'audio/wav': ['.wav'],
                    'audio/x-aiff': ['.aif']
                }}
                multiple={this.isMultipleFileUploadAllowed()}
            >
                {({getRootProps, getInputProps, isFocused, isDragAccept, isDragReject}) => (
                    <Box sx={{
                        flex: 1,
                        minHeight: 125,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '20px',
                        borderWidth: 2,
                        borderRadius: 2,
                        borderColor: this.getDropZoneColor({isFocused: isFocused, isDragAccept: isDragAccept, isDragReject: isDragReject}),
                        borderStyle: 'dashed',
                        backgroundColor: '#1e1e1e',
                        color: '#bdbdbd',
                        outline: 'none',
                        transition: 'border .24s ease-in-out',
                        '&:hover': {
                            borderColor: '#2196f3'
                        },
                        // marginBottom: 4,
                        [`@media only screen and (max-width: 700px)`]: {
                            display: 'none',
                        },
                    }}>
                        <section>
                            <div {...getRootProps({isFocused, isDragAccept, isDragReject})}>
                                <input {...getInputProps()} />
                                <CloudUploadIcon sx={{
                                    height: 40,
                                    width: 40,
                                    color: "#FFFFFF"
                                }}/>
                                <Typography variant={"body1"} color={"secondary"} textAlign={"center"}>
                                    {this.getDropZoneDisplayText()}
                                </Typography>
                            </div>
                        </section>
                    </Box>
                )}
            </Dropzone>
        )
    }
}