import {UPLOAD_STATUS} from "../pages/SyncFiles";
import {withRouter} from "../wrappers/withRouter";
import {FileDropzoneBase} from "../FileDropzoneBase";

class SongPageFileDropZone extends FileDropzoneBase {
    constructor(props) {
        super(props);
    }

    /**
     * @override
     * allow multiple files to be uploaded
     * 
     * @returns {boolean}
     */
    isMultipleFileUploadAllowed() {
        return true;
    }

    getDropZoneDisplayText() {
        return 'upload';
    }

    /**
     * callback for when the files have been dropped
     * @param acceptedFiles
     */
    async onDrop(acceptedFiles) {
        // set our internal state
        this.setState({
            files: acceptedFiles
        });


        this.props.router.navigate(`/app/sync`, {
            replace: true,
            state: {
                files: acceptedFiles,
                uploadStatus: UPLOAD_STATUS.VERIFY_SONGS
            }
        });
    }

    /**
     * using the current state of the dropzone, change the styling appropriately
     * @param props
     * @returns {string}
     */
    getDropZoneColor(props) {
        console.log("grabbing color! with props", props);
        if (props.isDragAccept) {
            return '#00e676';
        }
        if (props.isDragReject) {
            return '#ff1744';
        }
        if (props.isFocused) {
            return '#2196f3';
        }
        return '#294591';
    }
}

export default withRouter(SongPageFileDropZone);