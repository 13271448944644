import BaseService from "./BaseService";

export class SongVersionNoteService extends BaseService {

    static resourceURI = 'songVersionNote';
    constructor() {
        super();
    }

    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }
}