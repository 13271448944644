import BaseService from "./BaseService";
import { rootUrl } from '../Utilities/Utils';

export class LyricsService extends BaseService {

    static resourceURI = 'lyrics';
    constructor() {
        super();
    }

    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }

    static async analyzeSentiment(params, token) {
        console.log("SERVICE: analyzeSentiment", params);

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formCreatePayload(params))
        };

        console.log("SERVICE: analyzeSentiment - request options", requestOptions);

        let resultsRaw = await fetch(`${rootUrl}/${this.resourceURI}/generate-sentiment-analysis`, requestOptions);
        let results = await resultsRaw.json();

        return results;
    }

    static async generateAlbumArtwork(params, token) {
        console.log("SERVICE: generateAlbumArtwork", params);

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formCreatePayload(params))
        };

        console.log("SERVICE: generateAlbumArtwork - request options", requestOptions);

        let resultsRaw = await fetch(`${rootUrl}/${this.resourceURI}/generate-artwork`, requestOptions);
        let results = await resultsRaw.json();

        return results;
    }

    static async generateNearRhymes(params, token) {
        console.log("SERVICE: generateNearRhymes", params);

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formCreatePayload(params))
        };

        console.log("SERVICE: generateNearRhymes - request options", requestOptions);

        let resultsRaw = await fetch(`${rootUrl}/${this.resourceURI}/generate-near-rhymes`, requestOptions);
        let results = await resultsRaw.json();

        return results;
    }

    static async generateMetaphors(params, token) {
        console.log("SERVICE: generateMetaphors", params);

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formCreatePayload(params))
        };

        console.log("SERVICE: generateMetaphors - request options", requestOptions);

        let resultsRaw = await fetch(`${rootUrl}/${this.resourceURI}/generate-metaphors`, requestOptions);
        let results = await resultsRaw.json();

        return results;
    }
}