import BaseService from './BaseService';

/**
 * @TODO create a generic service that takes in the URL parts and constructs the correct URL + payload
 * maybe a hook for the createBody of certain payloads e.g. getUpdatePayload
 */
export class ProjectService extends BaseService {

    static resourceURI = 'project';
    constructor() {
        super();
    }

    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }
}